import get from 'lodash/get';
import { useNurlaStore } from '@/store';

interface Tenant {
  id: string,
  domain: string,
}

export const lookupTenant = async (domain:string) => {
  const config = useRuntimeConfig();
  const params = {
    domain,
  };
  const res:{ tenant:Tenant } = await $fetch(
    config.public.apiBaseUrl + '/tenants/lookup',
    { params, method: 'GET' },
  );
  return res;
};

export const getUi = async ({ page } : { page:string }) => {
  const config = useRuntimeConfig();

  // I'd use getDefaultOptions here, but useCookie generates an error in SSR for some reason, even
  // though it is supposed to be "SSR-friendly". Fortunately, don't need the token for this request.
  const nurlaStore = useNurlaStore();
  const tenantId = get(nurlaStore, 'tenant.id', '');
  const options = {
    headers: {
      'X-Tenant-Id': tenantId,
    },
    params: {
      page,
    },
  };
  const res = await useFetch(
    config.public.apiBaseUrl + '/tenants/ui',
    { ...options, method: 'GET' },
  );
  return res;
};
