export const getRate = async () => {
  const config = useRuntimeConfig();
  const params = {
    lender: 'Homebuyer.com',
    loanType: 'Conventional',
    price: '98',
  };
  const res = await $fetch(
    config.public.apiBaseUrl + '/rates/current',
    { params, method: 'GET' },
  );
  return res;
};

export const getNationalAverageRate = async () => {
  const config = useRuntimeConfig();
  const params = {
    loanType: 'Conventional',
  };
  const res = await $fetch(
    config.public.apiBaseUrl + '/rates/fred/current',
    { params, method: 'GET' },
  );
  return res;
};
